import React, { Fragment } from "react";
import jo from "../assets/jo.jpg";
// import ben from "../assets/ben.jpg";
import vithu from "../assets/vithu.jpg";
import niro from "../assets/niro.jpg";
import sachin from "../assets/sachin.jpg";
import tharun from "../assets/tharun.jpg";
import kiruba from "../assets/kiruba.jpg";
import siva from "../assets/siva.jpg";
import mang from "../assets/mang.jpg";
import nithu from "../assets/nithu.jpg";
import mith from "../assets/mith.png";
import "./Team.css";
import { Helmet } from "react-helmet-async";
import TeamCard from "../components/TeamCard";

export default function Team() {
  const teamList = [
    {
      id: 1,
      name: "Josiah Prathaban",
      image: jo,
      designation: "Co-Founder",
      linkedin: "https://www.linkedin.com/in/josiah-prathaban/",
      color: "#26ff76",
      about: (
        <>
          Josiah, a recent software engineering graduate from University of Kelaniya, transitioned into game development.
          He has honed his skills by working on numerous Web App projects. Despite his technical expertise, Josiah's true passion lies in creative design. His mesmerizing designs have earned
          him recognition in multiple communities, where he has made significant contributions.
          With his unique blend of technical and artistic abilities, he is poised to make a lasting impact in
          Software Business.
        </>
      ),
    },
    // {
    //   id: 2,
    //   name: "Ben Hanan Subendran",
    //   image: ben,
    //   designation: "Co-Founder",
    //   linkedin: "https://www.linkedin.com/in/hanancs/",
    //   color: "#cc00ff",
    //   about: (
    //     <>
    //       Ben is a gradute from IIT, Sri Lanka. Currently, he is a mobile developer.
    //       He enjoys creating things that live on the internet,
    //       whether that be websites, applications, or anything in between. He's interested in DevOps
    //       and dedicated to improving the efficiency and reliability
    //       of systems through automation, continuous integration, and deployment. He's constantly learning and refining
    //       his skills to stay up-to-date with the latest technologies and trends.
    //     </>
    //   ),
    // },
    {
      id: 3,
      name: "Kiruba Karan",
      image: kiruba,
      designation: "Front-End Engineer",
      linkedin: "https://www.linkedin.com/in/kiruba-karan-a75684179/",
      color: "#ffb570",
      about: (
        <>
          Kiruba is a software engineering graduate from University of Kelaniya. He is currently working as a mobile developer.
          He is predominantly into Front-end development. He has worked on multiple Flutter/React based projects and possesses a solid understanding of JavaScript and Dart.
          Additionally, Kiruba has a keen interest in user interface design and machine learning. He is an enthusiastic and
          skilled individual with a passion for exploring new technologies.
        </>
      ),
    },
    {
      id: 4,
      name: "Niroshan Vijayarasha",
      image: niro,
      designation: "Back-End Engineer",
      linkedin: "https://www.linkedin.com/in/niroshanrajh/",
      color: "#00de5c",
      about: (
        <>
          Niro, a graduate of the University of Kelaniya's renowned software engineering program, With his current focus on back-end
          projects, he has honed his skills in NodeJS and PHP, gaining a much broader knowledge of these technologies.
          In addition to his academic pursuits, Niro is the driving force behind the dynamic and tech-oriented YouTube Channel,
          Nirosh Media. Niro consistently delivers engaging and informative content that empowers his viewers to stay at the forefront of
          cutting-edge technologies.
        </>
      ),
    },
    {
      id: 5,
      name: "Mangalan Gnanasegaram",
      image: mang,
      designation: "Infrastructure  Engineer",
      linkedin: "https://www.linkedin.com/in/mangalan-gnanasegaram/",
      color: "#ffc400",
      about: (
        <>
          Mangalan, a software engineering graduate from the University of Kelaniya,
          is a cybersecurity enthusiast with a passion for networking and a knack for
          navigating the intricacies of information security. Driven by a thirst for
          challenges and a commitment to achieving organizational goals, Mangalan possesses
          a diverse skillset encompassing cryptography, penetration testing, and ethical
          hacking. His mind, a fortress of knowledge, decipher the complexities of cyber threats, enabling
          him to devise ingenious solutions to protect vulnerable systems.
        </>
      ),
    },
    {
      id: 6,
      name: "Sachin Ram",
      image: sachin,
      designation: "Full-Stack Engineer",
      linkedin: "https://www.linkedin.com/in/sachchin-ram-06a022182/",
      color: "#00faff",
      about: (
        <>
          Sachin is a software engineering graduate from the University of Kelaniya. He has expertise in Angular & .NET
          gaining excellent knowledge in JavaScript, C# & PHP. Sachin has a keen interest in software development with
          knowledge of algorithms, data structure, object-oriented programming, and relational databases.
          He has the potential to make an impact in the tech industry. His boundless potential and unwavering dedication to
          excellence make him a force to be reckoned with in the world of software development.
        </>
      ),
    },
    {
      id: 7,
      name: "Tharun Varshanth Krishnamoorthy",
      image: tharun,
      designation: "Technical Consultant",
      linkedin: "https://www.linkedin.com/in/tharun-varshanth-krishnamoorthy/",
      color: "#ff004c",
      about: (
        <>
          Tharun, a recent software engineering graduate from the University of Kelaniya,
          brings a fresh perspective and exceptional technical skills to his role as a
          technical consultant. Despite his limited industry experience, Tharun has
          demonstrated remarkable aptitude in advising and supporting developers, analysts,
          and technical specialists across various architectural domains. His expertise lies in cloud infrastructures and
          Microservices, areas in high demand in today's tech landscape.
        </>
      ),
    },
    {
      id: 8,
      name: "Ravikrishnan Vithushan",
      image: vithu,
      designation: "Full Stack Engineer",
      linkedin: "https://www.linkedin.com/in/ravikrishnan-vithushan-63a142179/",
      color: "#008cff",
      about: (
        <>
          Introducing Vithu, a proud alumnus of the University of Kelaniya, now flourishing as a Full Stack Engineer.
          As a JavaScript enthusiast, he is enamored with MERN stack development and has undertaken
          several projects in this domain. Vithu's diverse interests span from business analysis and strategic
          planning to data science, reflecting his multifaceted talents and ambitions. His insatiable thirst for
          knowledge and innovation drives him towards excellence and he strives for continuous improvement.
        </>
      ),
    },
    {
      id: 9,
      name: "Sivanujan Sachchithanantham",
      image: siva,
      designation: "Front-End Engineer",
      linkedin: "https://www.linkedin.com/in/ssivanujan1998/",
      color: "#00ff00",
      about: (
        <>
          Meet Siva, a Software Engineering graduate from the University of Kelaniya.
          His primary focus is on developing web applications, and he has worked on several projects using React.
          He has a solid understanding of JavaScript and enjoys keeping up-to-date with the latest
          web development trends and technologies. He is committed to creating exceptional software and his attention
          to detail is impeccable. Siva's resourcefulness and determination set him apart and he is a proactive problem solver.
        </>
      ),
    },
    {
      id: 10,
      name: "Nithursan.M",
      image: nithu,
      designation: "Strategy Consultant",
      linkedin: "https://www.linkedin.com/in/nithur/",
      color: "#fbff00",
      about: (
        <>
          Nithursan, a University of Kelaniya alumnus with a degree in Software Engineering,
          is an innovative indie maker and an inspirational blogger. His enthusiasm for Data Science and AI
          is reflected in his insightful writings that aim to motivate and enlighten others. Demonstrating his
          inventive flair, he created Hypergraphia, a unique browser extension that helps writers set and monitor
          their writing goals.
        </>
      ),
    },
    {
      id: 11,
      name: "Rajamiththiran Varatharajan",
      image: mith,
      designation: "Full Stack Engineer",
      linkedin: "https://www.linkedin.com/in/raja-miththiran-b6ba671a5/",
      color: "#fff4e3",
      about: (
        <>
          Rajamiththiran, a Software Engineering undergraduate at the University of Kelaniya,
          is a dynamic and skilled full-stack engineer specializing in JavaScript. His passion extends
          to areas such as machine learning, embedded systems, IoT development, and agriculture, showcasing
          his diverse interests in technology. His proactive approach and dedication to learning make him
          an integral part of any tech-driven team.
        </>
      ),
    },
  ];

  return (
    <Fragment>
      <Helmet>
        <title>Keture | Our Team</title>
        <meta name="title" content="Keture | Our Team"></meta>
        <meta
          name="description"
          content="Meet the talented and dedicated team behind Keture. Our diverse group of professionals brings a wealth of experience and expertise to every project, ensuring exceptional results and client satisfaction."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://keture.com/" />
        <meta property="og:title" content="Keture | Our Team" />
        <meta
          property="og:description"
          content="Meet the talented and dedicated team behind Keture. Our diverse group of professionals brings a wealth of experience and expertise to every project, ensuring exceptional results and client satisfaction."
        />
        <meta
          property="og:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />

        {/* Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://keture.com/" />
        <meta property="twitter:title" content="Keture | Our Team" />
        <meta
          property="twitter:description"
          content="Meet the talented and dedicated team behind Keture. Our diverse group of professionals brings a wealth of experience and expertise to every project, ensuring exceptional results and client satisfaction."
        />
        <meta
          property="twitter:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />
      </Helmet>
      <section className="leading-relaxed max-w-screen-xl pt-12 mx-auto px-4 lg:px-8 mb-20">
        <div className="space-y-3 text-center">
          <h1 className="text-5xl text-gray-400 font-black">Meet Our Team</h1>
          <p className="text-gray-500 max-w-lg mx-auto text-lg">
            Great Engineers. Great Minds.
          </p>
        </div>
        <div className="mt-14 gap-10 sm:grid sm:grid-cols-2 lg:grid-cols-3">
          {teamList.map((member) => (
            <TeamCard member={member} />
          ))}
        </div>
        {/* <svg
        className="absolute top-0 left-1/2 -z-10 h-[42.375rem] -translate-x-1/2 blur-3xl xl:-top-6"
        viewBox="0 0 1155 678"
        fill="none"
      >
        <path
          fill="url(#09dbde42-e95c-4b47-a4d6-0c523c2fca9a)"
          fillOpacity=".2"
          d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
        />
        <defs>
          <linearGradient
            id="09dbde42-e95c-4b47-a4d6-0c523c2fca9a"
            x1="1155.49"
            x2="-78.208"
            y1=".177"
            y2="474.645"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#f97316" />
            <stop offset={1} stopColor="#fbbf24" />
          </linearGradient>
        </defs>
      </svg> */}
      </section>
    </Fragment>
  );
}
