import "./Comp.css";
import React, { useState, useEffect } from "react";
import parse from 'html-react-parser';

export default function TestimonialSlider(props) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const testimonials = [
    {
      name: "Vinojan Veerapathirathasan",
      jobTitle: "Founder and CEO, DecHorizon",
      quote: `<div> Our experience with Keture's knowledge sharing sessions has been truly invaluable. I am impressed by the depth of insights and expertise they bring to the table. Keture's sessions have provided us with practical and actionable information on industry best practices, emerging technologies, and trends. </div>

       <div classname="mt-3"> I wholeheartedly recommend Keture to any organization seeking transformative knowledge sharing experiences. Thank you, Keture, for your exceptional sessions and ongoing support. </div>
        `,
      imageUrl: "https://media.licdn.com/dms/image/D5603AQG6syrrcvRBDA/profile-displayphoto-shrink_800_800/0/1675914905040?e=2147483647&v=beta&t=eu6XxPW93rvFdjxLFbQtU9QFDrizm5op5AVUdILEJfs",
    },
  ];

  const numSlides = testimonials.length;

  useEffect(() => {
    // Autoplay functionality
    const interval = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % numSlides);
    }, 5000);
    return () => clearInterval(interval);
  }, [currentSlide, numSlides]);

  function nextSlide() {
    const numSlides = testimonials.length;
    setCurrentSlide((currentSlide + 1) % numSlides);
  }

  function prevSlide() {
    const numSlides = testimonials.length;
    setCurrentSlide((currentSlide + numSlides - 1) % numSlides);
  }

  const currentTestimonial = testimonials[currentSlide];

  return (
    <>
      <div className="max-w-5xl mx-auto space-y-2 rounded-md  bg-white/5 p-5 ring-1 ring-white/10 opacity-90 isolate overflow-hidden relative">
        <figure className="max-w-screen-md mx-auto text-center pt-5">
          <svg
            aria-hidden="true"
            className="w-12 h-12 mx-auto mb-3 text-lime-400"
            viewBox="0 0 24 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
              fill="currentColor"
            />
            
          </svg>
          <blockquote>
            <p className="">
            { parse (currentTestimonial.quote) }
            </p>
          </blockquote>
          <figcaption className="flex items-center justify-center mt-6 space-x-3">
            <img
              className="w-6 h-6 rounded-full"
              src={currentTestimonial.imageUrl}
              alt="profilePicture"
            />
            <div className="flex items-center divide-x-2 divide-gray-700">
              <cite className="pr-3 font-medium text-white">
                {currentTestimonial.name}
              </cite>
              <cite className="pl-3 text-sm text-gray-400">
                {currentTestimonial.jobTitle}
              </cite>
            </div>
          </figcaption>
        </figure>
        <div className="mt-6">
          <button
            onClick={prevSlide}
            className="text-2xl font-bold text-gray-500 focus:outline-none hover:text-gray-700"
          >
            &lt;
          </button>
          <button
            onClick={nextSlide}
            className="text-2xl font-bold text-gray-500 focus:outline-none hover:text-gray-700"
          >
            &gt;
          </button>
        </div>
      </div>
    </>
  );
}
