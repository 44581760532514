import React, { Fragment } from "react";
import "./About.css";
import cc1 from "../assets/cc1.svg";
import cc2 from "../assets/cc2.svg";
import cc3 from "../assets/cc3.svg";
import { Helmet } from "react-helmet-async";

export default function About() {
  return (
    <Fragment>
      <Helmet>
        <title>Keture | About</title>
        <meta name="title" content="Keture | About"></meta>
        <meta
          name="description"
          content="Our cutting-edge technology and personalized approach help our clients streamline operations, increase productivity, and drive growth. Discover how Keture can transform your business today."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://keture.com/" />
        <meta property="og:title" content="Keture | About" />
        <meta
          property="og:description"
          content="Our cutting-edge technology and personalized approach help our clients streamline operations, increase productivity, and drive growth. Discover how Keture can transform your business today."
        />
        <meta
          property="og:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />

        {/* Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://keture.com/" />
        <meta property="twitter:title" content="Keture | About" />
        <meta
          property="twitter:description"
          content="Our cutting-edge technology and personalized approach help our clients streamline operations, increase productivity, and drive growth. Discover how Keture can transform your business today."
        />
        <meta
          property="twitter:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />
      </Helmet>
      <section className="leading-relaxed mx-auto px-4 lg:px-8 abt-back py-20">
        <div className="space-y-3 text-center">
          {/* <h1 className="text-5xl text-gray-400 font-black">About Keture</h1> */}

          <img src={cc1} alt="img" className="object-center h-20 m-auto" />
          <h1 className="text-5xl text-gray-400 font-black">Our Purpose</h1>
          <p className="text-gray-500 max-w-lg mx-auto text-lg italic">
            “If we did all the things we were capable of, we would astound
            ourselves” - Thomas Edison
          </p>
          <p className="text-gray-400 max-w-3xl mx-auto text-lg text-left pb-20">
            At Keture, our mission is to empower and enhance the Mannar community by leveraging the power of technology. Our vision is to create a computerized society that seeks innovative solutions through digital means. As our business grows, we are determined to deliver world-class software and solutions that can compete on the global market, thereby strengthening our country's economy.

            However, our ultimate goal extends far beyond commercial success. We are committed to bridging the digital divide in Mannar and ensuring that everyone has access to the tools and skills they need to succeed in today's digital world. We firmly believe that digital literacy, digital skills, democracy, social mobility, economic equality, and economic growth are all interconnected, and we are dedicated to contributing to each of these areas as much as we can.

            Through our work at Keture, we hope to make a meaningful and lasting impact on the Mannar community and beyond. By providing cutting-edge technology solutions and fostering digital innovation, we are confident that we can help create a brighter, more prosperous future for Sri Lanka and South Asia as a whole.
          </p>

          <img src={cc2} alt="img" className="object-center h-20 m-auto" />
          <h1 className="text-5xl text-gray-400 font-black ">Our Approach </h1>
          <p className="text-gray-500 max-w-lg mx-auto text-lg italic ">
            “Integrity is doing the right thing even when no one is watching” -
            C.S Lewis
          </p>
          <p className="text-gray-400 max-w-3xl mx-auto text-lg text-left pb-20">
            At our company, we believe in encouraging all kinds of business efforts, regardless of their market worth or branding value. However, we hold ourselves to a high ethical standard and will never partner with the tobacco industry, gambling (betting) businesses, or the porn industry. We believe that such industries can be harmful to our community, and we are committed to contributing to the greater good.

            We firmly reject any proposal that comes from these entities and will not be swayed by any potential financial gain. Our focus is on making society a better place, and we believe that this goal is best achieved by supporting businesses that align with our values.

            We understand the importance of creating a sustainable and ethical business environment, and we are committed to upholding these values in everything we do. Our goal is to build a business that not only succeeds financially but also makes a positive impact on the world around us.
          </p>

          <img src={cc3} alt="img" className="object-center h-20 m-auto" />
          <h1 className="text-5xl text-gray-400 font-black">Our Culture </h1>
          <p className="text-gray-500 max-w-lg mx-auto text-lg italic ">
            “Company culture matters.” - Unknown
          </p>
          <p className="text-gray-400 max-w-3xl mx-auto text-lg text-left pb-10">
            At Keture, we understand the real power of teamwork, and we believe that great things are achieved when people come together to work towards a common goal. We value each individual's unique perspective and believe that diversity is essential to our success.

            We make it a point to respect each other's lifestyles and beliefs, regardless of who we are or what our standards may be. Our mission is deeply inclusive, and we believe that everyone has a valuable role to play in society. We are committed to improving the technology-saturated world we live in, but we recognize the importance of doing so in the right way.

            We understand that technology can be both a blessing and a curse, and we are determined to use it for good. We believe that by working together, we can achieve great things and make a positive impact on the world around us. At Keture, we are proud to be part of a team that is dedicated to creating a better future for all.
          </p>
        </div>
      </section>
    </Fragment>
  );
}
