import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";

export default function Policy() {
  return (
    <Fragment>
      <Helmet>
        <title>Keture | Policy</title>
        <meta name="title" content="Keture | Policy"></meta>
        <meta
          name="description"
          content="Keture, a software company based in Mannar, is an up-and-coming small entity to impact the community with a small workforce unlike big tech giants around the world."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://keture.com/" />
        <meta property="og:title" content="Keture | Policy" />
        <meta
          property="og:description"
          content="Keture, a software company based in Mannar, is an up-and-coming small entity to impact the community with a small workforce unlike big tech giants around the world."
        />
        <meta
          property="og:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />

        {/* Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://keture.com/" />
        <meta property="twitter:title" content="Keture | Policy" />
        <meta
          property="twitter:description"
          content="Keture, a software company based in Mannar, is an up-and-coming small entity to impact the community with a small workforce unlike big tech giants around the world."
        />
        <meta
          property="twitter:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />
      </Helmet>
      <div>
        <div className="relative flex min-h-screen flex-col justify-center overflow-hidden py-6 sm:py-12">
          <div className="relative bg-slate-200 px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-screen-lg sm:rounded-lg sm:px-10">
            <div className=" max-w-screen-lg">
              <div className="divide-y divide-gray-300/50">
                <div className="space-y-6 py-8 text-base leading-7 text-gray-600">
                  <h1 className="text-3xl md:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-slate-800 to-slate-700">
                    Privacy Policy for{" "}
                    <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-red-600">
                      Keture
                    </span>{" "}
                    (Pvt) Ltd.
                  </h1>
                  <p>Last updated: July 09, 2022</p>
                  <p>
                    <span className="font-extrabold">
                      Please read these privacy policy carefully before using
                      Our Service.
                    </span>
                  </p>
                  <p>
                    At{" "}
                    <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-red-600">
                      Keture 
                    </span>{" "}
                     site, accessible from{" "}
                    <a href="https://keture.com">
                      https://
                      <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-red-600">
                        keture
                      </span>
                      .com
                    </a>
                    , one of our main priorities is the privacy of our visitors.
                    This Privacy Policy document contains types of information
                    that is collected and recorded by{" "}
                    <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-red-600">
                      Keture
                    </span>{" "}
                    site and how we use it.
                  </p>

                  <p>
                    If you have additional questions or require more information
                    about our Privacy Policy, do not hesitate to contact us.
                  </p>

                  <p>
                    This Privacy Policy applies only to our online activities
                    and is valid for visitors to our website with regards to the
                    information that they shared and/or collect in{" "}
                    <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-red-600">
                      Keture
                    </span>{" "}
                    site. This policy is not applicable to any information
                    collected offline or via channels other than this website.
                  </p>

                  <h2 className="text-xl md:text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-slate-800 to-slate-700">
                    Consent
                  </h2>

                  <p>
                    By using our website, you hereby consent to our Privacy
                    Policy and agree to its terms.
                  </p>

                  <h2 className="text-xl md:text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-slate-800 to-slate-700">
                    Information we collect
                  </h2>

                  <p>
                    The personal information that you are asked to provide, and
                    the reasons why you are asked to provide it, will be made
                    clear to you at the point we ask you to provide your
                    personal information.
                  </p>
                  <p>
                    If you contact us directly, we may receive additional
                    information about you such as your name, email address,
                    phone number, the contents of the message and/or attachments
                    you may send us, and any other information you may choose to
                    provide.
                  </p>
                  <p>
                    When you register for an Account, we may ask for your
                    contact information, including items such as name, company
                    name, address, email address, and telephone number.
                  </p>

                  <h2 className="text-xl md:text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-slate-800 to-slate-700">
                    How we use your information
                  </h2>

                  <p>
                    We use the information we collect in various ways, including
                    to:
                  </p>

                  <ul className="divide-y-2 divide-gray-400">
                    <li className="flex justify-between p-3">
                      Provide, operate, and maintain our website
                    </li>
                    <li className="flex justify-between p-3">
                      Improve, personalize, and expand our website
                    </li>
                    <li className="flex justify-between p-3">
                      Understand and analyze how you use our website
                    </li>
                    <li className="flex justify-between p-3">
                      Develop new products, services, features, and
                      functionality
                    </li>
                    <li className="flex justify-between p-3">
                      Communicate with you, either directly or through one of
                      our partners, including for customer service, to provide
                      you with updates and other information relating to the
                      website, and for marketing and promotional purposes
                    </li>
                    <li className="flex justify-between p-3">
                      Send you emails
                    </li>
                    <li className="flex justify-between p-3">
                      Find and prevent fraud
                    </li>
                  </ul>

                  <h2 className="text-xl md:text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-slate-800 to-slate-700">
                    Log Files
                  </h2>

                  <p>
                    <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-red-600">
                      Keture
                    </span>{" "}
                    site follows a standard procedure of using log files. These
                    files log visitors when they visit websites. All hosting
                    companies do this and a part of hosting services' analytics.
                    The information collected by log files include internet
                    protocol (IP) addresses, browser type, Internet Service
                    Provider (ISP), date and time stamp, referring/exit pages,
                    and possibly the number of clicks. These are not linked to
                    any information that is personally identifiable. The purpose
                    of the information is for analyzing trends, administering
                    the site, tracking users' movement on the website, and
                    gathering demographic information.
                  </p>

                  <h2 className="text-xl md:text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-slate-800 to-slate-700">
                    Advertising Partners Privacy Policies
                  </h2>

                  <p>
                    You may consult this list to find the Privacy Policy for
                    each of the advertising partners of{" "}
                    <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-red-600">
                      Keture
                    </span>{" "}
                    site.
                  </p>

                  <p>
                    Third-party ad servers or ad networks uses technologies like
                    cookies, JavaScript, or Web Beacons that are used in their
                    respective advertisements and links that appear on{" "}
                    <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-red-600">
                      Keture
                    </span>{" "}
                    site, which are sent directly to users' browser. They
                    automatically receive your IP address when this occurs.
                    These technologies are used to measure the effectiveness of
                    their advertising campaigns and/or to personalize the
                    advertising content that you see on websites that you visit.
                  </p>

                  <p>
                    Note that{" "}
                    <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-red-600">
                      Keture
                    </span>{" "}
                    site has no access to or control over these cookies that are
                    used by third-party advertisers.
                  </p>

                  <h2 className="text-xl md:text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-slate-800 to-slate-700">
                    Third Party Privacy Policies
                  </h2>

                  <p>
                    <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-red-600">
                      Keture
                    </span>{" "}
                    site's Privacy Policy does not apply to other advertisers or
                    websites. Thus, we are advising you to consult the
                    respective Privacy Policies of these third-party ad servers
                    for more detailed information. It may include their
                    practices and instructions about how to opt-out of certain
                    options.{" "}
                  </p>

                  <p>
                    You can choose to disable cookies through your individual
                    browser options. To know more detailed information about
                    cookie management with specific web browsers, it can be
                    found at the browsers' respective websites.
                  </p>

                  <h2 className="text-xl md:text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-slate-800 to-slate-700">
                    CCPA Privacy Rights (Do Not Sell My Personal Information)
                  </h2>

                  <p>
                    Under the CCPA, among other rights, California consumers
                    have the right to:
                  </p>
                  <p>
                    Request that a business that collects a consumer's personal
                    data disclose the categories and specific pieces of personal
                    data that a business has collected about consumers.
                  </p>
                  <p>
                    Request that a business delete any personal data about the
                    consumer that a business has collected.
                  </p>
                  <p>
                    Request that a business that sells a consumer's personal
                    data, not sell the consumer's personal data.
                  </p>
                  <p>
                    If you make a request, we have one month to respond to you.
                    If you would like to exercise any of these rights, please
                    contact us.
                  </p>

                  <h2 className="text-xl md:text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-slate-800 to-slate-700">
                    GDPR Data Protection Rights
                  </h2>

                  <p>
                    We would like to make sure you are fully aware of all of
                    your data protection rights. Every user is entitled to the
                    following:
                  </p>
                  <p>
                    The right to access – You have the right to request copies
                    of your personal data. We may charge you a small fee for
                    this service.
                  </p>
                  <p>
                    The right to rectification – You have the right to request
                    that we correct any information you believe is inaccurate.
                    You also have the right to request that we complete the
                    information you believe is incomplete.
                  </p>
                  <p>
                    The right to erasure – You have the right to request that we
                    erase your personal data, under certain conditions.
                  </p>
                  <p>
                    The right to restrict processing – You have the right to
                    request that we restrict the processing of your personal
                    data, under certain conditions.
                  </p>
                  <p>
                    The right to object to processing – You have the right to
                    object to our processing of your personal data, under
                    certain conditions.
                  </p>
                  <p>
                    The right to data portability – You have the right to
                    request that we transfer the data that we have collected to
                    another organization, or directly to you, under certain
                    conditions.
                  </p>
                  <p>
                    If you make a request, we have one month to respond to you.
                    If you would like to exercise any of these rights, please
                    contact us.
                  </p>

                  <h2 className="text-xl md:text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-slate-800 to-slate-700">
                    Children's Information
                  </h2>

                  <p>
                    Another part of our priority is adding protection for
                    children while using the internet. We encourage parents and
                    guardians to observe, participate in, and/or monitor and
                    guide their online activity.
                  </p>

                  <p>
                    <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-red-600">
                      Keture
                    </span>{" "}
                    site does not knowingly collect any Personal Identifiable
                    Information from children under the age of 13. If you think
                    that your child provided this kind of information on our
                    website, we strongly encourage you to contact us immediately
                    and we will do our best efforts to promptly remove such
                    information from our records.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
