import React, { useEffect } from "react";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { Outlet, useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <HelmetProvider>
      <div className="bg-gradient-to-br from-gray-900 to-gray-700">
        <NavBar />
        <Outlet />
        <Footer />
      </div>
    </HelmetProvider>
  );
}
