import React, { Fragment } from "react";
import "./About.css";
import fa from "../assets/projects/fish-app.png";
import im from "../assets/projects/imso.png";
import nf from "../assets/projects/office-nexus.png";
import rs from "../assets/projects/rms.png";
import { Helmet } from "react-helmet-async";
export default function About() {
  const projects = [
    {
      title: "F3 Seafood",
      img: fa,
      description: "Sri Lanka's #1 Seafood Bidding App",
      bg: "bg-gradient-to-t from-cyan-700 to-transparent",
    },
    {
      title: "IMSO",
      img: im,
      description: "PWA for improving internal operations of OPEnE",
      bg: "bg-gradient-to-t from-sky-800 to-transparent",
    },
    {
      title: "OfficeNexus",
      img: nf,
      description: "An Office Management System for NGOs in Sri Lanka",
      bg: "bg-gradient-to-t from-blue-900 to-transparent",
    },
    {
      title: "DecHorizon RMS",
      img: rs,
      description: "A Recruitment Management System by DecHorizon",
      bg: "bg-gradient-to-t from-teal-600 to-transparent",
    },
  ];
  return (
    <Fragment>
      <Helmet>
        <title>Keture | Projects</title>
        <meta name="title" content="Keture | Projects"></meta>
        <meta
          name="description"
          content="Explore our impressive portfolio of projects at Keture. From website design to mobile app development, our team has delivered exceptional solutions for a diverse range of clients. Discover how we can help bring your vision to life with our expertise and innovative approach."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://keture.com/" />
        <meta property="og:title" content="Keture | Projects" />
        <meta
          property="og:description"
          content="Explore our impressive portfolio of projects at Keture. From website design to mobile app development, our team has delivered exceptional solutions for a diverse range of clients. Discover how we can help bring your vision to life with our expertise and innovative approach."
        />
        <meta
          property="og:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />

        {/* Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://keture.com/" />
        <meta property="twitter:title" content="Keture | Projects" />
        <meta
          property="twitter:description"
          content="Explore our impressive portfolio of projects at Keture. From website design to mobile app development, our team has delivered exceptional solutions for a diverse range of clients. Discover how we can help bring your vision to life with our expertise and innovative approach."
        />
        <meta
          property="twitter:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />
      </Helmet>
      <section className="leading-relaxed grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3  2xl:grid-cols-4">
        {projects.map((project) => (
          <div className="relative h-96 overflow-hidden">
            <img
              src={project.img}
              alt="img"
              className="object-center w-full m-auto"
            />
            <div className={`absolute h-full w-full top-0 ${project.bg}`}></div>
            <div className="absolute bottom-0 p-5 drop-shadow-[0_10px_35px_rgb(0,0,0)]">
              <div className="text-xl font-extrabold drop-shadow-[0_0px_35px_rgb(0,0,0)]">
                {project.title}
              </div>
              <p className=" text-sm">{project.description}</p>
            </div>
          </div>
        ))}
      </section>
    </Fragment>
  );
}
